<template>
  <div class="announcements">
    <div class="container">


      <div class="row">
        <div class="col d-none d-lg-flex">
          <img src="../assets/my-img/law.png" style="max-height: 400px; margin-bottom: -100px; margin-left: 120px;"
            alt="">
        </div>
        <div class="col">
          <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner mt-5">
              <div class="carousel-item active">
                <div class="item container">
                  <h1 class="title text-start px-3 animate fadeInRight">Facebook</h1>
                  <h6 class="details text-start px-3 animate fadeInRight one"><i class="bi bi-facebook px-1" style="font-size: 2rem;"></i> Uniunea Naţională a Executorilor Judecătoreşti din România
                  </h6>
                  <div class="d-flex justify-content-start px-3"><a href="https://www.facebook.com/profile.php?id=61560413607750"
                      class="btn btn-announcements mt-2">DESCHIDE</a>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="item container">
                  <h1 class="title text-start px-3 animate fadeInRight">SiLex</h1>
                  <h6 class="details text-start px-3 animate fadeInRight one">Aplicația informatică SiLEx are ca scop
                    digitalizarea și standardizarea
                    activității
                    de executare silită <a href="#" data-bs-toggle="modal" data-bs-target="#ModalANNOUNCEMENTS"
                      class="text-light">citește
                      mai mult...</a>
                  </h6>
                  <div class="d-flex justify-content-start px-3"><a href="https://silex.executori.ro"
                      class="btn btn-announcements mt-2">DESCHIDE</a>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="item container">
                  <h1 class="title text-start px-3 animate fadeInRight">Harta executorilor</h1>
                  <h6 class="details text-start px-3 animate fadeInRight one">Este o secțiune ușor de folosit care vă
                    ajută
                    la căutarea rapidă a
                    executorilor
                    din proximitatea dumneavoastră.</h6>
                  <div class="d-flex justify-content-start px-3"><router-link to="/harta-executorilor"
                      class="btn btn-announcements mt-2">DESCHIDE</router-link></div>

                </div>
              </div>
            </div>
            <div class="next-prev d-flex justify-content-end mt-5">
              <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev" aria-hidden="true"><i
                  class="bi bi-chevron-compact-left d-flex justify-content-center"></i></button>

              <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next" aria-hidden="true"><i
                  class="bi bi-chevron-compact-right d-flex justify-content-center"></i></button>
            </div>
          </div>
        </div>
      </div>





    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="ModalANNOUNCEMENTS" tabindex="-1" aria-labelledby="ModalANNOUNCEMENTS" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <h5 class="modal-title mt-10 text-center">SiLex</h5>
        <div class="modal-body">
          <span>
            Aplicația informatică SiLEx are ca scop digitalizarea și standardizarea activității
            de executare silită și este pusă la dispoziție executorilor judecătorești, în mod gratuit, în
            scopul
            eficientizării activității birourilor executorilor judecătorești, asigurării unei interconectări
            cu
            sistemul integrat existent la nivelul UNEJ, precum și al gestionarii dosarelor de executare
            silită.
          </span><br /><br />
          <span>
            Adresa web: <a href="https://silex.executori.ro">www.silex.executori.ro</a>
          </span>
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger" data-bs-dismiss="modal">Închide</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.status {
    padding-left: 2px;
    padding-right: 2px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: rgb(228, 7, 7);
    color: white;
    font-size: 1.5rem;
    margin-right: 5px;
}

.announcements {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.3)), url(../assets/my-img/ro.jpg);
  background-repeat: round;
  height: 500px;
  margin-top: -30px;
  color: white;
  display: flex;
  align-items: center;
  // border-bottom-left-radius: 300px;
  // border-bottom-right-radius: 300px;
}

@media only screen and (max-width : 768px) {
  .announcements {
    margin-top: -60px;
  }
}

.btn-announcements {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  text-decoration: none;
  height: 30px;
  font-size: 10px;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: white;
  border: solid 1px rgb(228, 228, 228);
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: white;
    color: black;
  }
}

.next-prev button {
  color: white;
  background-color: rgba(128, 128, 128, 0.6);
  height: 40px;
  width: 40px;
  margin: 0 1px;
  border: none;
  border-radius: 30px;

  &:hover {
    background-color: rgba(128, 128, 128, 0.4);
  }
}
</style>